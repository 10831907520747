import { BaseResponse } from './common';

export type Address = {
  addressKey?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  zip4?: string;
  postalCode?: string;
  label?: string;
  value?: string;
  env?: string;
  controlNumber?: string;
  latitude?: number;
  longitude?: number;
  parsedAddress?: ParsedAddress;
  dma?: string;
  dmaName?: string;
};

export type TipData = {
  geo: {
    ip: string;
    ispName: string;
    continent: string;
    zipcode: number;
    organizationName: string;
    latitude: number;
    longitude: number;
    isPostalCodeEstimate: boolean;
    region: string;
    country: string;
    district: string;
    city: string;
  };
  ua: {
    browserName: string;
    isBot: boolean;
    isTablet: boolean;
    osName: string;
    osVersion: string;
    isDesktop: boolean;
    isMobile: boolean;
  };
  demographics: {
    AverageHouseValue: number;
    Population: number;
    IncomePerHousehold: number;
    PersonsPerHousehold: number;
    LandArea: number;
    HispanicPopulation: number;
    ZipCode: string;
    PrimaryRecord: string;
    HouseholdsPerZipcode: number;
    MalePopulation: number;
    FemalePopulation: number;
    MedianAge: number;
    MedianAgeMale: number;
    MedianAgeFemale: number;
    Elevation: number;
    County: string;
    CountyFIPS: string;
    StateFIPS: string;
    TimeZone: string;
    DayLightSaving: string;
    Msa: string;
    Pmsa: string;
    Csa: string;
    Cbsa: string;
    CbsaDiv: string;
    CBSAType: string;
    CBSAName: string;
    MSAName: string;
    PMSAName: string;
    Division: string;
    MailingName: string;
    NumberOfBusinesses: number;
    NumberOfEmployees: number;
    BusinessFirstQuarterPayroll: number;
    BusinessAnnualPayroll: number;
    BusinessEmploymentFlag: string;
    GrowthRank: number;
    GrowingCountiesA: number;
    GrowingCountiesB: number;
    GrowthIncreaseNumber: number;
    GrowthIncreasePercentage: number;
    CBSAPopulation: number;
    CBSADivisionPopulation: number;
    CongressionalDistrict: 10 | 11;
    CongressionalLandArea: 1372.24 | 185.09;
    DeliveryResidential: 20393;
    DeliveryBusiness: 700;
    DeliveryTotal: 25016;
    PreferredLastLineKey: string;
    ClassificationCode: string;
    MultiCounty: string;
    CSAName: string;
    CBSADIVName: string;
    CityStateKey: string;
    PopulationEstimate: number;
    WaterArea: number;
    CityAliasCode: string;
    CityMixedCase: string;
    CityAliasMixedCase: string;
    BoxCount: number;
    Sfdu: number;
    Mfdu: number;
    StateANSI: string;
    CountyANSI: string;
    ZIPIntroDate: string;
    AliasIntroDate: string;
    FacilityCode: string;
    CityDeliveryIndicator: string;
    CarrierRouteRateSortation: string;
    FinanceNumber: string;
    UniqueZIPName: string;
    SSAStateCountyCode: string;
    MedicareCBSACode: string;
    MedicareCBSAName: string;
    MedicareCBSAType: string;
    MarketRatingAreaID: number;
  };
  errors: null;
};

type ParsedAddress = {
  streetNumber?: string;
  streetPreDirectional?: string;
  streetName?: string;
  streetSuffix?: string;
  streetPostDirectional?: string;
};

export type AddressPredictive = {
  addressKey?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  zipCode: string;
  zipCode4?: string;
  latitude?: string | number;
  longitude?: string | number;
  parsedAddress: ParsedAddress;
};

export type AddressAllconnect = {
  addressKey: string;
  address?: string;
  address2?: string;
  city: string;
  isUnit?: boolean;
  latitude?: boolean;
  longitude?: boolean;
  primary?: string;
  secondary?: string;
  smartyStreetsMismatch?: boolean;
  street_line?: string;
  street?: string;
  streetName?: boolean;
  streetNumber?: boolean;
  postDirection?: string;
  preDirection?: string;
  point?: Point;
  state: string;
  unitType?: boolean;
  unitValue?: boolean;
  zip: string;
  zip4?: string;
  zip9?: string;
  zip5?: string;
  zip9or5?: string;
  prettyAddress?: string;
  hasFullAddress?: boolean;
  addressUpdatedCount?: number;
  parsedAddress?: ParsedAddress;
};

type Point = {
  latitude: number;
  longitude: number;
};

export type Eligibility = {
  addressHasExistingService: boolean;
  isMovers?: boolean;
  serviceable?: boolean;
};

export type Promotion = {
  name: string;
  description: string | null;
};

export interface ServiceabilityResponse extends BaseResponse {
  addressHasExistingService?: boolean;
  addressKey?: string;
  env?: string;
  controlNumber?: string;
  inFootprint?: boolean;
  fiberModernization?: boolean;
  futureServiceDate?: string | null;
  serviceType?: string;
  offerType?: string;
  useUnserveSoft?: boolean;
  fiberBuildOutStatus?: string;
  unservReason?: string;
  designatedMarketArea?: string;
  designatedMarketAreaDescription?: string;
  tipDataFromZipCode?: TipData;
  voiceEligible?: boolean;
}

// Redux
export enum ActionTypes {
  SET_ADDRESS = 'address/set',
  UPDATE_ADDRESS = 'address/update',
  SET_ELIGIBILITY = 'eligibility/set',
  SET_UNSERV_REASON = 'unservReason/set',
  SET_PROMOTIONS = 'promotions/set',
  RESET_ADDRESS_ROUTE = 'route/address/reset',
  ADDRESS_HISTORY = 'address/history/add',
  SET_VOICE_ELIGIBLE = 'address/voiceEligible/set',
}

// Actions
export interface AddressAction {
  type: ActionTypes.SET_ADDRESS;
  address: Address;
  key: string;
}

export interface UpdateAddressAction {
  type: ActionTypes.UPDATE_ADDRESS;
  address: Address;
  key: string;
}

export interface EligibilityAction {
  type: ActionTypes.SET_ELIGIBILITY;
  eligibility: Eligibility;
}

export interface UnservReasonAction {
  type: ActionTypes.SET_UNSERV_REASON;
  unservReason: string;
}

export interface PromotionsAction {
  type: ActionTypes.SET_PROMOTIONS;
  promotions: Promotion[];
}

export type ResetAddressRouteAction = {
  type: ActionTypes.RESET_ADDRESS_ROUTE;
};

export interface AddressHistoryAction {
  type: ActionTypes.ADDRESS_HISTORY;
  addressKey: string;
}
export interface SetVoiceEligibleAction {
  type: ActionTypes.SET_VOICE_ELIGIBLE;
  voiceEligible: boolean;
}

export type Actions =
  | AddressAction
  | UpdateAddressAction
  | EligibilityAction
  | PromotionsAction
  | ResetAddressRouteAction
  | AddressHistoryAction
  | UnservReasonAction
  | SetVoiceEligibleAction;
