import {
  Arrows as ArrowsOutline,
  Bill as BillOutline,
  CheckCircle as CheckCircleOutline,
  CircleUp as CircleUpOutline,
  Coin as CoinOutline,
  DiagonalRight as DiagonalRightOutline,
  Ban as BanOutline,
  Download as DownloadOutline,
  Error as ErrorOutline,
  Gamepad as GamepadOutline,
  Globe as GlobeOutline,
  InfoCircle as InfoCircleOutline,
  LaptopOpen as LaptopOpenOutline,
  Monitor as MonitorOutline,
  MultipleUsers as MultipleUsersOutline,
  Star as StarOutline,
  Stopwatch as StopwatchOutline,
  Ticket as TicketOutline,
  WifiBox as WifiBoxOutline,
  Wrench as WrenchOutline,
  Gift as GiftOutline,
  Gift2 as Gift2Outline,
  WifiBoxWhite as WifiBoxWhiteOutline,
  StopwatchRed as StopwatchRedOutline,
  ConfirmationCheck as ConfirmationCheckOutline,
  CompareBill,
  CheckCircle,
  MagnifyingGlass,
  FrontierLogo
} from '~/components/Icons/outline';
import {
  Bolt as BoltFill,
  Check as CheckFill,
  DollarBill as DollarBillFill,
  Gift as GiftFill,
  Headset as HeadsetFill,
  Lock as LockFill,
  Phone as PhoneFill,
  Play as PlayFill,
  PlayCircle as PlayCircleFill,
  Speedometer as SpeedometerFill,
  Star as StarFill,
  Unlock as UnlockFill,
  Users as UsersFill,
  Video as VideoFill,
  Wifi as WifiFill,
  Indicator as Indicator,
} from '~/components/Icons/fill';

export const iconMap = {
  bolt: BoltFill,
  check: CheckFill,
  'check-circle-outline': CheckCircle,
  confirmationCheck: ConfirmationCheckOutline,
  'compare-bill': CompareBill,
  'dollar-bill': DollarBillFill,
  giftFill: GiftFill,
  headset: HeadsetFill,
  lock: LockFill,
  phone: PhoneFill,
  'play-icon': PlayFill,
  users: UsersFill,
  unlock: UnlockFill,
  video: VideoFill,
  wifi: WifiFill,
  arrows: ArrowsOutline,
  ban: BanOutline,
  bill: BillOutline,
  checkCircle: CheckCircleOutline,
  circleup: CircleUpOutline,
  coin: CoinOutline,
  diagonalright: DiagonalRightOutline,
  download: DownloadOutline,
  error: ErrorOutline,
  gamepadOut: GamepadOutline,
  giftOutline: GiftOutline,
  gift2Outline: Gift2Outline,
  globe: GlobeOutline,
  infoCircle: InfoCircleOutline,
  laptopopen: LaptopOpenOutline,
  'magnifying-glass': MagnifyingGlass,
  monitor: MonitorOutline,
  multipleusers: MultipleUsersOutline,
  'play-circle': PlayCircleFill,
  speedometer: SpeedometerFill,
  starFill: StarFill,
  starOutline: StarOutline,
  stopwatch: StopwatchOutline,
  stopwatchRed: StopwatchRedOutline,
  ticket: TicketOutline,
  wifiBox: WifiBoxOutline,
  wifiBoxWhite: WifiBoxWhiteOutline,
  wrench: WrenchOutline,
  indicator: Indicator,
  frontierLogo: FrontierLogo
};

export const notificationsIconMap = {
  error: ErrorOutline,
  success: CheckCircleOutline,
  warning: InfoCircleOutline,
};
