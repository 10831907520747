import React, { FC } from 'react';
import slugify from '~/helpers/slugify';
import { H1, H2 } from '../Typography';
import PageHeaderStyled from './PageHeaderStyled';
import Subheader from './components/Subheader';

const HeadingsMap = {
  1: H1,
  2: H2,
};

interface Props {
  title: string;
  className?: string;
  subtitle?: {
    icon?: JSX.Element;
    text: string;
  };
  headingLevel?: 1 | 2;
  children?: JSX.Element[] | JSX.Element | string;
  isEeroPage?: boolean;
}

const PageHeader: FC<Props> = ({ title, subtitle, headingLevel, children, className, isEeroPage }) => {
  const Heading = HeadingsMap[headingLevel];
  return (
    <PageHeaderStyled inline={children} className={className} isEeroPage={isEeroPage} data-testid={slugify(title)}>
      <div className="page-header__titles" data-testid="page-header">
        <Heading data-testid="plan-package-header">{title}</Heading>
        <Subheader {...subtitle} />
      </div>
      {children}
    </PageHeaderStyled>
  );
};

PageHeader.defaultProps = {
  headingLevel: 2,
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
