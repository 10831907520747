import Cookie from 'js-cookie';
import {
  ActionTypes,
  CallInNumberAction,
  PageLoadingAction,
  TrackingAction,
  RemoveAcpAction,
  RemoveTokenAction,
  MonarchDecisionPayload,
  MonarchDecisionAction,
  CountdownExpiration,
  Copper2FiberAction,
  PreampAction,
  CommercialFeeConsentAction,
  CommercialCCFeeAction,
  CardTypeAction,
  ResumeAction,
  CookiePassAction,
  SessionExpiredAction,
  ResetStateAction,
  ExpirationBannerAction,
  RemoveExpirationBannerAction,
  SingleFormAction,
  HideDebugAction,
  InstallationOptions,
  SetInstallationOptionsAction,
  SetDiscountOptionsAction,
  SetLiwPageReasonAction,
  LiwPageReasons,
  SetCustomOffersAction,
  DiscountOptions,
  SetBuyflowRedirectDelayAction
} from '~/types/common';

export const setTracking = (key: string, id: string): TrackingAction => {
  return { type: ActionTypes.SET_TRACKING, key, id };
};

export const setPageLoading = (loading: boolean): PageLoadingAction => {
  return { type: ActionTypes.SET_PAGE_LOADING, loading };
};

export const setCallInNumber = (callInNumber: string): CallInNumberAction => {
  return { type: ActionTypes.SET_CALL_IN_NUMBNER, callInNumber };
};

export const removeACP = (): RemoveAcpAction => {
  return { type: ActionTypes.REMOVE_ACP };
};

export const removeToken = (): RemoveTokenAction => {
  Cookie.remove('allconnect-recaptcha');
  Cookie.remove('frontierSiteDetailPredictive');
  return { type: ActionTypes.REMOVE_TOKEN };
};

export const removeExpirationBanner = (): RemoveExpirationBannerAction => {
  return { type: ActionTypes.REMOVE_EXPIRATION_BANNER };
};

export const setMonarchDecision = (payload: MonarchDecisionPayload): MonarchDecisionAction => {
  return { type: ActionTypes.SET_MONARCH_DECISION, payload };
};

export const setPreamp = (key: string, value: Record<string, unknown> | string | number | boolean): PreampAction => {
  return { type: ActionTypes.SET_PREAMP_ACTION, key, value };
};

export const setCountdownExpiration = (countdownExpiration: string): CountdownExpiration => {
  return { type: ActionTypes.COUNTDOWN_EXPIRATION, countdownExpiration };
};

export const setC2F = (key: string, value: boolean | string): Copper2FiberAction => {
  return { type: ActionTypes.SET_C2F, key, value };
};

export const setResume = (key: string, value: boolean | string): ResumeAction => {
  return { type: ActionTypes.SET_RESUME, key, value };
};

export const setSingleForm = (key: string, value: boolean | string | number): SingleFormAction => {
  return { type: ActionTypes.SET_SINGLE_FORM, key, value };
};

export const setCommercialFeeConsent = (commercialFeeConsent: boolean): CommercialFeeConsentAction => {
  return { type: ActionTypes.SET_FEE_CONSENT, commercialFeeConsent };
};

export const setCommercialCCFee = (commercialCreditCardfee: number): CommercialCCFeeAction => {
  return { type: ActionTypes.SET_COMMERCIAL_CC_FEE, commercialCreditCardfee };
};

export const setCardType = (cardType: string): CardTypeAction => {
  return { type: ActionTypes.SET_CARD_TYPE, cardType };
};

export const setCookiePass = (cookiePass: boolean): CookiePassAction => {
  return { type: ActionTypes.SET_COOKIE_PASS, cookiePass };
};

export const setSessionExpired = (expired: boolean): SessionExpiredAction => {
  return { type: ActionTypes.SET_SESSION_EXPIRED, expired };
};

export const resetState = (): ResetStateAction => {
  return { type: ActionTypes.RESET_STATE };
};

export const setExpirationBanner = (key: string, value: boolean | string): ExpirationBannerAction => {
  return { type: ActionTypes.SET_EXPIRATION_BANNER, key, value };
};

export const setHideDebug = (value: boolean): HideDebugAction => {
  return { type: ActionTypes.SET_HIDE_DEBUG, value };
};

export const setInstallationOptions = (
  key: string,
  value: InstallationOptions | string | boolean
): SetInstallationOptionsAction => {
  return { type: ActionTypes.SET_INSTALLATION_OPTIONS, key, value };
};

export const setDiscountOptions = (value: DiscountOptions): SetDiscountOptionsAction => {
  return { type: ActionTypes.SET_DISCOUNT_OPTIONS, value };
};

export const setLiwPageReason = (value: LiwPageReasons): SetLiwPageReasonAction => {
  return { type: ActionTypes.SET_LIW_PAGE_REASON, reason: value };
};

export const setCustomOffers = (key: string, value: string | number | boolean): SetCustomOffersAction => {
  return { type: ActionTypes.SET_CUSTOM_OFFERS, key, value };
};

export const setBuyflowRedirectDelay = (value: number): SetBuyflowRedirectDelayAction => {
  return { type: ActionTypes.SET_BUYFLOW_REDIRECT_DELAY, value };
};
