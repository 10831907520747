import React, { FC, useState } from 'react';
import { Tooltip as Tippy, TooltipProps } from 'react-tippy';
import { trackEvent } from '~/helpers/tracking';
import { TooltipStyled } from './TooltipStyled';

interface Props extends TooltipProps {
  children?: (string | JSX.Element)[] | JSX.Element[] | JSX.Element | string;
  onClick?: () => void;
  skipRewards?: boolean;
  tag?: string;
}

const Tooltip: FC<Props> = (props) => {
  const [tooltipTracked, setTooltipTracked] = useState(false);
  const { children, onClick, skipRewards, html } = props;
  const tooltipText = html?.props?.children?.props?.children || html?.props?.children;

  const handleOnClick = (event) => {
    event.preventDefault();
    if (onClick) onClick();
  };

  const trackTooltipEvent = () => {
    if (!tooltipTracked) {
      setTooltipTracked(true);
      trackEvent({
        action: 'toolTip',
        data: {
          detail: {
            text: tooltipText,
          },
        },
      });
    }
  };

  return (
    <TooltipStyled onClick={(event) => handleOnClick(event)} skipRewards={skipRewards} onMouseEnter={trackTooltipEvent}>
      <Tippy tag="span" distance={20} arrow arrowSize="big" sticky {...props}>
        <span className="tooltip-button">{children}</span>
      </Tippy>
    </TooltipStyled>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
