import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { FuelContext } from '~/providers/FuelProvider';
import { PAGE_ROUTES } from '~/constants/pages';
import { ReduxState } from '~/types/redux';
import { Preamp } from '@red-digital/bricks';
import { LoaderMetadata } from './components/EditableLoader';

const Default = dynamic(() => import('./components/Default'));
const AddressCheck = dynamic(() => import('./components/AddressCheck'));
const CreditCheck = dynamic(() => import('./components/CreditCheck'));
const Checkout = dynamic(() => import('./components/Checkout'));
const EditableLoader = dynamic(() => import('./components/EditableLoader'));

const Loader = (): JSX.Element => {
  const { router } = useContext(FuelContext);
  const { pathname: currentPage } = router;
  const { pageLoading } = useSelector((state: ReduxState) => state);

  const addressCheck = currentPage === PAGE_ROUTES.ADDRESS_CHECK;
  const creditCheckPage = currentPage === PAGE_ROUTES.CONTACT_CREDIT;
  const productSelectionStep = currentPage === PAGE_ROUTES.PLAN_PACKAGE;
  const fiber = currentPage === PAGE_ROUTES.BUY_FIBER;
  const resume = currentPage === PAGE_ROUTES.RESUME;
  const myCustomQuote = currentPage === PAGE_ROUTES.MY_CUSTOM_QUOTE;
  const checkout = currentPage === PAGE_ROUTES.CHECKOUT;

  const loadingText = (() => {
    if (productSelectionStep) return 'Just a moment, we’re getting your plan ready for activation.';
    if (creditCheckPage) return 'This step may take up to 2 minutes.';
    if (fiber) return 'Searching for the best fiber upgrades.';
    if (resume) return 'Loading your previous selections.';
    return 'Just a moment...';
  })();
  const loadingTextHeader = creditCheckPage ? 'Verifying your identity' : '';

  // Move this check outside of the Preamp placement so we don't get
  // a flash of the confirmation page before redirecting to Frontier's page
  if (currentPage.includes('confirmation')) {
    return <Default currentPage={currentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} />;
  }

  return (
    <Preamp placement="1FZh5t6bx0Tv3QrZiwVBCS">
      {(metadata: LoaderMetadata) => {
        if (!pageLoading || myCustomQuote) return null;

        if (metadata?.[currentPage]?.enabled === 'true') {
          return <EditableLoader metadata={metadata} currentPage={currentPage} />;
        }

        if (addressCheck || fiber) {
          return <AddressCheck />;
        }

        if (creditCheckPage) {
          return <CreditCheck />;
        }

        if (checkout) {
          return <Checkout />;
        }

        return <Default currentPage={currentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} />;
      }}
    </Preamp>
  );
};

Loader.displayName = 'Loader';

export default Loader;
