import { ActionTypes, Actions } from '~/types/billing';
import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';
import merge from 'lodash/merge';


const billing = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_PAPER_BILL:
      return assign({}, state, {
        paperBill: action.paperBill,
      });
    case ActionTypes.SET_PAPER_BILL_FEE:
      return assign({}, state, {
        paperBillFee: action.paperBillFee,
      });
    case ActionTypes.SET_AUTO_PAY:
      return merge({}, state, {
        autoPay: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.RESET_AUTO_PAY:
      return assign({}, state, {
        autoPay: {
          accepted: true,
          methodSelected: 'ACH',
          methodEntered: undefined,
          discountChangeAccepted: false,
          error: false,
        },
      });
    case ActionTypes.SET_UPFRONT_CHARGE:
      return merge({}, state, {
        upfrontCharge: {
          [action.key]: action.value,
        },
      });
    default:
      return state;
  }
};

export default billing;
