import clientSide from '~/helpers/clientSide';
import { ReduxState } from '~/types/redux';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import ftrAttribution from '~/helpers/mapFtrAttribution';
import { getSiteData, getSiteKey } from '~/helpers/site';
import { getQuoteId } from '~/helpers/getQuoteId';
import { addressFormatPredictive } from '../../helpers/addressFormatter';
import { MONARCH_SOURCE_ID, MONARCH_TOKEN } from '~/constants/config/common';
import { APOptions } from '~/types/order';

let correlationId;
const geCorrelationId = () => {
  if (!correlationId) correlationId = uuid();
  return correlationId;
};

const getClientSideTrackingIds = () => {
  const clientSideTrackingIds = Cookies.get('clientSideTrackingIds');

  if (clientSideTrackingIds) return JSON.parse(clientSideTrackingIds);

  const clientSessionId = uuid();
  const orderId = uuid();
  const in1Hour = 1 / 24;
  const trackingIds = { clientSessionId, orderId, correlationId: geCorrelationId() };
  Cookies.set('clientSideTrackingIds', JSON.stringify(trackingIds), { expires: in1Hour });
  return trackingIds;
};

const getSessionState = (sessionStorageState) => {
  const sessionState = JSON.parse(sessionStorageState) as ReduxState;

  // Remove keys that should not be persisted.
  const blocklist = ['autoSubmitAddress', 'notifications', 'pageLoading'];
  blocklist.forEach((key) => delete sessionState[key]);

  return sessionState;
};

export const getDefaultState = (production: boolean): ReduxState => {
  const trackingIds = getClientSideTrackingIds();
  const state: ReduxState = {
    production,
    tracking: {
      ...trackingIds,
      ...ftrAttribution(),
      clientId: `fuel-cart-${process.env.environment}-${getSiteKey()}`,
      writeKey: getSiteData('writeKey'),
      orderSessionId: trackingIds.clientSessionId,
      siteKey: getSiteKey(),
      monarchSourceId: MONARCH_SOURCE_ID,
      monarchToken: MONARCH_TOKEN,
      retryAttempts: '0',
    },
    discountOptions: {
      tracking: [],
    },
    address: {},
    addressHistory: [],
    autoSubmitAddress: false,
    autoPay: {
      accepted: true,
      methodSelected: APOptions.ACH,
      methodEntered: undefined,
      discountChangeAccepted: false,
      error: false,
    },
    upfrontCharge: {
      methodSelected: APOptions.DEBIT,
      methodEntered: undefined,
    },
    paperBill: false,
    monarch: {},
    preamp: {},
    callInNumber: '',
    amexOffer: false,
    cookiePass: true,
    sessionExpired: false,
    quiz: {
      quizStarted: false,
      currentQuestionIndex: 0,
      answers: {},
      quizTotal: -1, // Initial total is -1 because some answer combinations give a negative total (never smaller than -1)
      seenResults: false,
      submissionCount: 0,
    },
    otp: {
      success: false,
      codesGenerated: 0,
      codeVerifyAttempts: 0,
      verificationError: false,
      verificationRequiredFromQuote: false,
      attemptsPerCode: {
        1: 0,
        2: 0,
      },
    },
    hideDebug: false,
    legal: {
      activeLegal: [],
      legalMap: {},
    },
    resume: {},
    installationOptions: {},
    appointment: null,
    notifications: [],
    customOffers: {}
  };

  //get the quoteId and add it for the buy/fiber flow
  const quoteId = getQuoteId();
  if (quoteId) {
    state.tracking.quoteId = quoteId;
  }

  return state;
};

// ACP (Affordable Connectivity Program) Querystring
const getACPQuerystring = (state) => {
  if (clientSide) {
    const searchParams = new URLSearchParams(document.location.search);

    // ACP Querystring
    const addressKey = searchParams.get('a');
    const controlNumber = searchParams.get('c');
    const env = searchParams.get('e');

    searchParams.delete('a');
    searchParams.delete('c');
    searchParams.delete('e');

    const params = Array.from(searchParams).length ? `?${searchParams.toString()}` : ``;
    window.history.replaceState({}, '', `${document.location.pathname}${params}`);

    if (env && controlNumber) {
      state.acp = { addressKey, env, controlNumber };
    }
  }
};

// Check for initial address set by cookie
const getAddressFromCookie = (state) => {
  const siteDetailCookie = Cookies.get('frontierSiteDetailPredictive');
  const siteDetails = siteDetailCookie ? JSON.parse(siteDetailCookie) : {};

  const acTokenCookie = Cookies.get('allconnect-recaptcha');
  const acAddressCookie = Cookies.get('address');
  if (acTokenCookie && acAddressCookie) {
    siteDetails.token = acTokenCookie;
    siteDetails.address = addressFormatPredictive(JSON.parse(acAddressCookie) || {});
  }

  if ((siteDetails?.address?.addressKey && siteDetails?.token) || (acTokenCookie && acAddressCookie)) {
    const {
      addressKey,
      addressLine1,
      addressLine2,
      city,
      stateProvince,
      zipCode,
      zipCode4 = '',
      latitude,
      longitude,
      parsedAddress,
    } = siteDetails.address;

    state.token = siteDetails.token;
    state.address = {
      install: {
        addressKey,
        address: addressLine1,
        address2: addressLine2,
        city,
        state: stateProvince,
        zip: zipCode,
        zip4: zipCode4,
        latitude: latitude,
        longitude: longitude,
        parsedAddress: parsedAddress,
      },
    };
  }
};

const getLPGdtResults = (state) => {
  const gdtResultsSessionStorage = clientSide && window?.sessionStorage.getItem('gdtResults');
  const gdtResults = gdtResultsSessionStorage ? JSON.parse(gdtResultsSessionStorage) : {};

  if (gdtResults?.internet && gdtResults?.addOns && gdtResults?.userCount) {
    state.quiz.results = {
      internet: gdtResults.internet,
      addOns: gdtResults.addOns
    };
    state.quiz.userCount = gdtResults.userCount;
    state.quiz.quizSession = true;
    state.quiz.quizSessionFromLP = true;
  }
}

const getInitialState = (production: boolean): ReduxState => {
  const sessionStorageState = clientSide && window.sessionStorage.getItem('persist:fuel');
  const state = sessionStorageState ? getSessionState(sessionStorageState) : getDefaultState(production);

  // append ACP param values to state
  getACPQuerystring(state);

  // If we have an address stored in a cookie auto populate it.
  getAddressFromCookie(state);

  // If we have recommended products stored in a cookie auto populate it.
  getLPGdtResults(state);

  return state;
};

export default getInitialState;
