import { Address, AddressPredictive, AddressAllconnect } from '../types/address';

const addressFormatter = (address: string, address2?: string): string => {
  if (address && address2) {
    return `${address} - ${address2}`;
  }

  return address;
};

export const addressFormatPredictive = (address: AddressAllconnect): AddressPredictive => {
  const addr = {
    addressKey: address?.addressKey || '',
    addressLine1: address?.street_line,
    addressLine2: address?.secondary || '',
    city: address?.city,
    stateProvince: address?.state,
    zipCode: address?.zip || '',
    zipCode4: address?.zip4 || '',
    latitude: address?.point?.latitude || '',
    longitude: address?.point?.longitude || '',
    parsedAddress: {
      streetName: '',
      streetNumber: address?.primary || '',
      streetPostDirectional: address?.postDirection || '',
      streetPreDirectional: address?.preDirection || '',
      streetSuffix: '',
    },
  };
  return addr;
};

export const addressFormatAllconnect = (address: Address): AddressAllconnect => {
  const { addressKey, city, state, zip, zip4 = '', latitude, longitude, parsedAddress = {} } = address;
  const addr = {
    addressKey,
    city,
    isUnit: false,
    latitude: false,
    longitude: false,
    primary: parsedAddress?.streetNumber,
    secondary: address?.address2 || '',
    smartyStreetsMismatch: false,
    street_line: address?.address,
    street: `${parsedAddress?.streetName} ${parsedAddress?.streetSuffix}`,
    streetName: false,
    streetNumber: false,
    postDirection: parsedAddress?.streetPostDirectional || '',
    preDirection: parsedAddress?.streetPreDirectional || '',
    point: { latitude, longitude },
    state,
    unitType: false,
    unitValue: false,
    zip,
    zip4,
    zip9: `${zip}-${zip4}`,
    zip5: zip,
    zip9or5: `${zip}-${zip4}`,
    prettyAddress: `${address?.address}, ${city}, ${state} ${zip}-${zip4}`,
    hasFullAddress: true,
    addressUpdatedCount: 0,
  };
  return addr;
};

export default addressFormatter;
