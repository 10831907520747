import { DefaultTheme } from 'styled-components';
import mediaQueries from './mediaQueries';

const breakpoints = {
  xsMobile: {
    maxWidth: 375,
    content: 364,
    gutter: 12,
  },
  mobile: {
    minWidth: 376,
    maxWidth: 767,
    content: 364,
    gutter: 6,
  },
  tablet: {
    minWidth: 768,
    maxWidth: 1223,
    content: 740,
    gutter: 6,
  },
  desktop: {
    minWidth: 1224,
    content: 1122,
    gutter: 6,
  },
};

const theme: DefaultTheme = {
  breakpoints,
  media: mediaQueries(breakpoints),
  colors: {
    primary: {
      aqua: '#74cdde',
      black: '#000',
      lightBlack: '#2c303e',
      darkBlue: '#141928',
      gravity: '#141928',
      gold: '#eeb33f',
      gray: '#58595b',
      navy: '#17355e',
      red: '#ff0037',
      activity: '#ff0037',
      royalBlue: '#026ce3',
      teal: '#2b7e93',
      white: '#fff',
      yellow: '#fcb832',
      aluminum: '#898C93',
      whiteSmoke: '#F3F4F4',
      celeste: '#96FFF5',
      electricity: '#96FFF5',
      aeronautic: '#2d3548'
    },
    secondary: {
      aqua: '#75cede',
      black: '#161617',
      gray: '#d1d2d4',
      green: '#009300',
      lightBlue: '#d0ecf4',
      lightGray: '#e7e7e8',
      mediumGray: '#5c5e61',
      mint: '#e4ffe4',
      navy: '#01315f',
      red: '#d9272d',
      royalBlue: '#025fca',
      teal: '#3e6688',
      white: '#fafafa',
      pumice: '#c4c5c9',
      aluminum: '#8A8C94',
    },
    tertiary: {
      white: '#f3f4f4',
      teal: '#96fff5',
      gray: '#e1e1e1',
      lightBlue: '#eafffd',
    },
    notification: {
      error: '#ffdddd',
      info: '#edf6ff',
      success: '#d8f7dd',
      warning: '#fff8c9',
      subtle: '#FFF2F5',
    },
    validation: {
      error: '#d80a07',
      success: '#027e17',
    },
    borders: {
      focus: 'rgba(0, 103, 244, 0.5)',
    },
  },
  shadows: {
    default: '0 20px 40px 0 rgba(0, 0, 0, 0.05)',
    darker: '0 10px 8px 0 rgba(0, 0, 0, 0.07)',
    darkest: '0px 0px 11px 0px rgb(0, 0, 0, 8%)',
    fallback: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  fonts: {
    avenir: "'avenir', Arial, sans-serif",
    avenirCondensed: "'avenir-next-condensed', Arial, sans-serif",
    bandwidth: "'bandwidth', sans-serif",
    objectSans: 'objectSans, Arial, sans-serif',
  },
};

export default theme;
